


























































































































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { IStudentEducation } from "@/interfaces/IStudentEducation";
import Validation from "@/components/Validation.vue";
import PlzInput from "@/components/Field/PlzInput.vue";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import Datepicker from "@/components/Datepicker.vue";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import { EDIT } from "@/constants/FormType";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import CrudService from "@/services/CrudService";
import BaseMixin from "@/mixins/BaseMixin";
import { COMPANY } from "@/constants/CostBearerTypeId";
import { CLASSIC_PAY, LIVE_PAY } from "@/constants/PaymentWorkflow";
import FilePlusButton from "@/components/Button/FilePlusButton.vue";
import BanButton from "@/components/Button/BanButton.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import moment from "moment";
import { DATE_FORMAT } from "@/constants/DateRegex";
import { v4 as uuidv4 } from "uuid";

const crudService = new CrudService();

@Component({
  components: {
    BanButton,
    FilePlusButton,
    FscCardHeader,
    Datepicker,
    PlzInput,
    Validation,
    ActionButton,
  },
})
export default class EducationItem extends mixins(StudentMixin, BaseMixin) {
  public name = "EducationItem";

  @Prop({ type: String })
  public title!: string;

  @Prop({ type: Object })
  public education!: IStudentEducation;

  @Prop({ type: Object })
  public v!: any;

  @Prop({ type: Number })
  public i!: any;

  @Prop({ type: Array, default: () => [] })
  public costBearersOption!: any;

  @Prop({ type: Array, default: () => [] })
  public titleOption!: any;

  @Prop({ type: Array, default: () => [] })
  public costBearerTypeIdOption!: any;

  @Prop({ type: Array, default: () => [] })
  public paymentWorkflowOptions!: any;

  @Prop({ type: Array, default: () => [] })
  public priceListOption!: any;

  @Prop({ type: Array, default: () => [] })
  public instructorOption!: any;

  @Prop({ type: Array, default: () => [] })
  public theoryCourseOption!: any;

  @Prop({ type: Array, default: () => [] })
  public licenseClassOption!: any;

  @Prop({ type: Array, default: () => [] })
  public licenseClassExistingOption!: any;

  @Prop()
  public type!: any;

  @Prop()
  public educationData!: any;

  @Prop()
  public livePayDeselected!: any;

  @Prop()
  public previousWorkflow!: any;

  @Prop({ type: Array, default: () => [] })
  public keysByLicenseClass!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  public grantingTypes!: Array<any>;

  public paymentWorkflowLocalOptions: any = [];

  public combinedTriggered = false;

  public secondTheoryCourseVisible = false;
  public thirdTheoryCourseVisible = false;

  public defaultPaymentWorkflow: any = "";

  public get sortedTheoryCourseOptions() {
    if (!this.theoryCourseOption) return [];
    return this.theoryCourseOption
      .map((option: any) => {
        const startDate = moment(option.startDate).format("DD.MM");
        const endDate = moment(option.endDate).format(DATE_FORMAT);
        return {
          ...option,
          name: `${startDate} - ${endDate} ${option?.name ? "| " + option.name : ""}`,
          sortingField: moment(`${option.startDate} ${option.timeFrame[0].startTime}`, "YYYY-MM-DD HH:mm"),
        };
      })
      .sort((a: any, b: any) => b.sortingField.diff(a.sortingField)); // sort by date desc
  }
  public mounted(): void {
    this.paymentWorkflowLocalOptions = this.paymentWorkflowOptions;
    this.defaultPaymentWorkflow = this.education.paymentWorkflow;
    this.triggerCombinedEducation();
  }

  public get keyNumberOptions() {
    if (this.keysByLicenseClass.length && this.education.licenseClassWithKeyNumber) {
      return this.keysByLicenseClass.map((key: any) => {
        return {
          licenseClass: this.education.licenseClassWithKeyNumber?.licenseClass ?? "",
          keyNumber: key,
        };
      });
    }
    return [];
  }

  private disableField(item: any): boolean {
    return this.typeEdit && Boolean(item.id);
  }

  protected get typeEdit(): boolean {
    return Boolean(this.type === EDIT);
  }

  private async onSelectLicenseClass(selectedOption: { licenseClass: string; keyNumber: any }): Promise<void> {
    this.education.grantingType = null;

    this.$emit("on-select-education", selectedOption.licenseClass);
    this.education.mainLicenseClass = selectedOption.licenseClass;

    this.triggerCombinedEducation();
  }

  public async onSelectKeyNumber(value: { licenseClass: string; keyNumber: any } | null) {
    this.education.grantingType = null;
    if (value?.licenseClass) {
      //Granting type logic
      const keyNumber = value.keyNumber ?? "";

      this.$emit("on-select-education", `${value.licenseClass}${keyNumber}`, false);

      this.triggerCombinedEducation();
    }
  }

  //Combined triggered logic
  public triggerCombinedEducation() {
    const classes = this.educationData.map((edc: any) => edc.mainLicenseClass);

    const currentLicenseClass = this.education.licenseClassWithKeyNumber?.licenseClass;

    if ((currentLicenseClass === "CE" && classes.includes("C")) || (currentLicenseClass === "C" && classes.includes("CE"))) {
      this.combinedTriggered = true;
      if (this.education.combined === null) this.education.combined = true;
    } else if ((currentLicenseClass === "C1E" && classes.includes("C1")) || (currentLicenseClass === "C1" && classes.includes("C1E"))) {
      this.combinedTriggered = true;
      if (this.education.combined === null) this.education.combined = true;
    } else {
      this.combinedTriggered = false;
      if (this.education.combined === null) this.education.combined = false;
    }
  }

  @Watch("grantingTypeOptions")
  private grantingTypeOptionsWatcher(grantingTypeOptions: Array<IBasicNamedDTO>): void {
    if (grantingTypeOptions.length === 1) {
      this.education.grantingType = grantingTypeOptions[0];
    }
  }

  public get grantingTypeOptions() {
    return this.grantingTypes;
  }

  @Watch("education.costBearerTypeId")
  public onChangeCostBearer(costBarer: any): void {
    if (costBarer && costBarer.id === COMPANY) {
      this.paymentWorkflowLocalOptions = this.paymentWorkflowOptions.filter((option: any) => option.id === CLASSIC_PAY);
      this.education.paymentWorkflow = this.paymentWorkflowLocalOptions[0];
    } else {
      this.paymentWorkflowLocalOptions = this.paymentWorkflowOptions;
    }
  }

  @Watch("paymentWorkflowOptions")
  public onPaymentWorkFlowOptionsChange(options: any): void {
    if (options) {
      this.paymentWorkflowLocalOptions = options;
      if (this.education.costBearerTypeId.id === COMPANY) {
        this.paymentWorkflowLocalOptions = options.filter((option: any) => option.id === CLASSIC_PAY);
      }
    }
  }

  protected get isLivePay(): boolean {
    return Boolean(this.education?.paymentWorkflow?.id === LIVE_PAY);
  }

  public get mandateable(): boolean {
    return Boolean(this.education.activeLivePayMandate !== null && this.isLivePay && this.typeEdit);
  }

  public get hasMandate(): boolean {
    return Boolean(this.education.activeLivePayMandate === true && this.mandateable);
  }

  public onEditEducationClick() {
    this.$emit("edit-education", this.education);
  }

  public activateEdit(title: any) {
    return (
      (title === "B" && this.$route.name === "StudentEdit") ||
      (title === "B197" && this.$route.name === "StudentEdit") ||
      (title === "B78" && this.$route.name === "StudentEdit")
    );
  }

  public addTheoryCourse() {
    if (!this.secondTheoryCourseVisible) {
      this.secondTheoryCourseVisible = true;
    } else if (this.secondTheoryCourseVisible && !this.thirdTheoryCourseVisible) {
      this.thirdTheoryCourseVisible = true;
    } else if (this.secondTheoryCourseVisible && this.thirdTheoryCourseVisible) {
      return;
    }
  }

  @Watch("livePayDeselected", { deep: true, immediate: true })
  public onLivePayDeselectChange(deselect: any) {
    if (deselect) {
      this.education.paymentWorkflow = this.previousWorkflow;
    }
  }

  public paymentWorkflowSelecting(option: any) {
    if (option) {
      if (option.id === 2 && this.type == EDIT) {
        this.$emit("live-pay-modal", this.defaultPaymentWorkflow);
      } else if (this.previousWorkflow && this.previousWorkflow.id) {
        this.defaultPaymentWorkflow = this.previousWorkflow;
        this.$emit("live-pay-selection");
      }
    }
  }

  private get showZeroVat() {
    return this.education.mainLicenseClass === "C1" || this.education.mainLicenseClass === "C1E";
  }

  private get zeroVatInfoId() {
    return `zero-vat-info-id-${this.uuid}`;
  }

  protected get uuid(): any {
    return uuidv4();
  }
}
