











































































import { Component, Prop, Provide, Watch } from "vue-property-decorator";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import FormCard from "@/components/FormCard.vue";
import Validation from "@/components/Validation.vue";
import StudentEducationTab from "@/views/Student/StudentEducationTab.vue";
import AddOnsPriceListTab from "@/views/Student/AddOnsPriceListTab.vue";
import DriveBuzzTab from "@/views/Student/DriveBuzzTab.vue";
import { Validations } from "vuelidate-property-decorators";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import { COST_BEARER_TYPES } from "@/constants/CostBearerTypeId";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import { TranslateResult } from "vue-i18n";
import TabMixin from "@/mixins/TabMixin";
import { IBranch } from "@/interfaces/IBranch";
import { IStudentEducationAndBillingAddress } from "@/interfaces/IStudentEducationAndBillingAddress";
import { IStudent } from "@/interfaces/IStudent";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import InstructorRequestMixin from "@/mixins/Request/InstructorRequestMixin";
import BranchRequestMixin from "@/mixins/Request/BranchRequestMixin";
import LanguageRequestMixin from "@/mixins/Request/LanguageRequestMixin";
import CountryRequestMixin from "@/mixins/Request/CountryRequestMixin";
import TuvRequestMixin from "@/mixins/Request/TuvRequestMixin";
import CourseRequestMixin from "@/mixins/Request/CourseRequestMixin";
import LicenseClassRequestMixin from "@/mixins/Request/LicenseClassRequestMixin";
import PaymentWorkflowRequestMixin from "@/mixins/Request/PaymentWorkflowRequestMixin";
import PriceListRequestMixin from "@/mixins/Request/PriceListRequestMixin";
import CostBearerRequestMixin from "@/mixins/Request/CostBearerRequestMixin";
import CheckListTemplateRequestMixin from "@/mixins/Request/CheckListTemplateRequestMixin";
import TestingOrganizationRequestMixin from "@/mixins/Request/TestingOrganizationRequestMixin";
import LivePayMandateRequestMixin from "@/mixins/Request/LivePayMandateRequestMixin";
import { MODULE_CUSTOMERS_WRITE } from "@/constants/Permissions";
import { namespace } from "vuex-class";
import RegistrationInfoModal from "@/views/Communication/RegistrationInfoModal.vue";
import RegistrationInfoButton from "@/components/Button/RegistrationInfoButton.vue";
import { CREATE } from "@/constants/FormType";
import { PropType } from "vue";
import { IStudentEducationWithGrantingTypesAndKeyNumbers } from "@/interfaces/IStudentEducationWithGrantingTypesAndKeyNumbers";
import KeyNumberRequestMixin from "@/mixins/Request/KeyNumberRequestMixin";
import GrantingTypeRequestMixin from "@/mixins/Request/GrantingTypeRequestMixin";
import moment from "moment";

const RegistrationsModule = namespace("registrations");
const MainTabsModule = namespace("main-tabs");

@Component({
  components: {
    FscPageHeader,
    FscCardHeader,
    DriveBuzzTab,
    AddOnsPriceListTab,
    StudentEducationTab,
    Validation,
    FormCard,
    AbortButton,
    SaveButton,
    RegistrationInfoModal,
    RegistrationInfoButton,
  },
})
export default class StudentForm extends mixins(
  StudentMixin,
  TabMixin,
  InstructorRequestMixin,
  BranchRequestMixin,
  LanguageRequestMixin,
  CountryRequestMixin,
  TuvRequestMixin,
  CourseRequestMixin,
  LicenseClassRequestMixin,
  PaymentWorkflowRequestMixin,
  PriceListRequestMixin,
  CostBearerRequestMixin,
  CheckListTemplateRequestMixin,
  TestingOrganizationRequestMixin,
  LivePayMandateRequestMixin,
  KeyNumberRequestMixin,
  GrantingTypeRequestMixin
) {
  public name = "StudentForm";

  public studentEducationActiveTabIndex = 0;
  public activeTab = 0;
  public activeChecklistTemplate = true;

  public emptyEducation: IStudentEducationAndBillingAddress = {
    id: null,
    bf17: false,
    additionalClass: false,
    fillingDate: null,
    mainLicenseClass: null,
    licenseClassWithKeyNumber: null,
    grantingType: null,
    firstExistingLicenseClass: null,
    secondExistingLicenseClass: null,
    firstExistingDrivingLicenseNumber: null,
    secondExistingDrivingLicenseNumber: null,
    firstExistingLicenseClassIssueDate: null,
    secondExistingLicenseClassIssueDate: null,
    costBearer: null,
    costBearerEmail: null,
    costBearerTypeId: COST_BEARER_TYPES[0],
    theoryCourse: null,
    secondTheoryCourse: null,
    thirdTheoryCourse: null,
    instructors: [],
    billingAddressId: null,
    billingAddressStudentEducationId: null,
    billingAddressTitle: null,
    billingAddressFirstName: null,
    billingAddressLastName: null,
    billingAddressPostalCode: null,
    billingAddressLocation: null,
    billingAddressStreetAndNumber: null,
    initialPriceList: null,
    paymentWorkflow: null,
    activeLivePayMandate: null,
    combined: null,
    automaticPaymentEnabled: false,
    practicalEducationFinishDate: null,
    theoreticalEducationFinishDate: null,
    processNumber: null,
    zeroVat: null,
  };

  public studentData: IStudent = {
    branch: null,
    title: null,
    lastName: "",
    theoryClass: null,
    grantType: null,
    paymentMethod: null,
    payers: null,
    firstName: null,
    postalCode: null,
    location: null,
    streetAndNumber: null,
    birthDate: null,
    birthPlace: null,
    email: null,
    mobilePhone: null,
    language: null,
    nationality: null,
    privatePhone: null,
    companyPhone: null,
    info: null,
    additionalInfo: null,
    returnDate: null,
    returnNumber: null,
    applicationCompletedDate: null,
    checkpoint: null,
    tuvService: null,
    visualAid: null,
    driversLicenseNumber: null,
    keyNumber: null,
    checklist: null,
    furtherEducationStudent: false,
    driveBuzzActivationDate: null,
    checkListTemplateId: null,
    testingOrganization: null,
    saldo: null,
    archived: null,
    driveBuzzLoginAllowed: null,
    learnStep: null,
    contractAccepted: null,
    studentEducationIds: null,
  };

  public educationData: any = [];

  public tabs = [
    {
      label: this.$t("students.student_data"),
    },
    {
      label: this.$t("general.drive_buzz"),
    },
    {
      label: this.getAddonsLabel,
    },
  ];

  protected bookedAddons = [];

  protected file: File | null = null;

  @Prop({ default: () => "" })
  public label!: string;

  @Prop({ default: () => [] })
  public priceLists!: any;

  @Prop({ default: () => [] })
  public addons!: any;

  @Prop({ default: () => false })
  public studentEducationIsLoading!: any;

  @Prop()
  public data!: any;

  @Prop()
  public type!: any;

  @Prop({ type: Array as PropType<Array<IStudentEducationWithGrantingTypesAndKeyNumbers>>, default: () => [] })
  public studentEducations!: Array<IStudentEducationWithGrantingTypesAndKeyNumbers>;

  @Prop({ default: () => false })
  public productAddonsSuccess!: any;

  @RegistrationsModule.Action("setSelectedStudent")
  public setSelectedUnregisteredStudent: any;

  @RegistrationsModule.Getter("getSelectedStudent")
  public selectedUnregisteredStudent: any;

  @Prop()
  public registrationInfo!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public popoverInfoShow = true;

  @MainTabsModule.Getter("getActive")
  public getActive: any;

  public keyNumbers: any = [];
  public grantingTypes: any = [];

  public mounted(): void {
    this.filterInstructor({ archived: false });
    this.findAllBranch({ archived: false });
    this.findAllLanguage({ archived: false });
    this.findAllCountry({ archived: false });
    this.findAllTuvServices({ archived: false });
    this.findAllTheoryCourse({ onlyActiveCourses: true });
    this.findAllBaseLicenseClass();
    this.findAllPaymentWorkflows();
    this.findAllPriceList();
    this.findAllCostBearers();
    this.findAllCheckListTemplates();
    this.filterTestingOrganizations({ archived: false });
    if (this.type == CREATE && this.selectedUnregisteredStudent === null) {
      this.pushEmptyEducation();
    }
  }

  @Watch("data", { immediate: true, deep: true })
  public async initData(data: any): Promise<void> {
    if (data) {
      this.studentData.email = data.email;
      this.studentData.location = data.location;
      this.studentData.privatePhone = data.privatePhone;
      this.studentData.companyPhone = data.companyPhone;
      this.studentData.nationality = data.nationality;
      this.studentData.applicationCompletedDate = data.applicationCompletedDate;
      this.studentData.visualAid = data.visualAid;
      this.studentData.firstName = data.firstName;
      this.studentData.lastName = data.lastName;
      this.studentData.branch = data.branch
        ? {
            id: data.branch.id,
            name: `${data.branch.postalCode} - ${data.branch.location}`,
          }
        : "";
      this.studentData.birthDate = data.birthDate;
      this.studentData.birthPlace = data.birthPlace;
      this.studentData.streetAndNumber = data.streetAndNumber;
      this.studentData.email = data.email;
      this.studentData.mobilePhone = data.mobilePhone;
      this.studentData.language = data.language;
      this.studentData.title = data.title;
      this.studentData.returnNumber = data.returnNumber;
      this.studentData.info = data.info || "";
      this.studentData.additionalInfo = data.additionalInfo;
      this.studentData.returnDate = data.returnDate;
      this.studentData.checkpoint = data.checkpoint;
      this.studentData.postalCode = data.postalCode;
      this.studentData.tuvService = data.tuvService;
      this.studentData.driveBuzzActivationDate = data.driveBuzzActivationDate;
      this.activeChecklistTemplate = false;
      this.studentData.testingOrganization = data.testingOrganization;
    }
  }

  @Watch("studentEducations", { immediate: false, deep: true })
  public studentEducationsInit(data: Array<IStudentEducationWithGrantingTypesAndKeyNumbers>): void {
    if (data.length > 0) {
      const internalEducation = [];
      for (const datum of data) {
        const item = datum.education;
        this.grantingTypes.push(datum.grantingTypes);
        this.keyNumbers.push(datum.keyNumbers);

        const costBearerTypeId = this.costBearerTypeIds.find((x: any) => x.id === item.costBearerTypeId);
        const education = {
          id: item.id,
          bf17: item.bf17,
          fillingDate: item.fillingDate,
          mainLicenseClass: item.mainLicenseClass,
          licenseClassWithKeyNumber: item.licenseClassWithKeyNumber,
          grantingType: item.grantingType,
          firstExistingLicenseClass: item.firstExistingLicenseClass,
          secondExistingLicenseClass: item.secondExistingLicenseClass,
          firstExistingDrivingLicenseNumber: item.firstExistingDrivingLicenseNumber,
          secondExistingDrivingLicenseNumber: item.secondExistingDrivingLicenseNumber,
          firstExistingLicenseClassIssueDate: item.firstExistingLicenseClassIssueDate,
          secondExistingLicenseClassIssueDate: item.secondExistingLicenseClassIssueDate,
          costBearer: item.costBearer,
          costBearerTypeId: costBearerTypeId,
          theoryCourse: item.theoryCourse,
          secondTheoryCourse: item.secondTheoryCourse,
          thirdTheoryCourse: item.thirdTheoryCourse,
          instructors: item.instructors,
          costBearerEmail: item.costBearerEmail,
          billingAddressId: item.billingAddress?.id,
          billingAddressStudentEducationId: item.billingAddress?.studentEducationId,
          billingAddressTitle: item.billingAddress?.title,
          billingAddressFirstName: item.billingAddress?.firstName,
          billingAddressLastName: item.billingAddress?.lastName,
          billingAddressPostalCode: item.billingAddress?.postalCode,
          billingAddressLocation: item.billingAddress?.location,
          billingAddressStreetAndNumber: item.billingAddress?.streetAndNumber,
          initialPriceList: item.initialPriceList,
          paymentWorkflow: item.paymentWorkflow,
          activeLivePayMandate: item.activeLivePayMandate,
          combined: item.combined,
          automaticPaymentEnabled: item.automaticPaymentEnabled,
          additionalClass: item.additionalClass,
          practicalEducationFinishDate: item.practicalEducationFinishDate,
          theoreticalEducationFinishDate: item.theoreticalEducationFinishDate,
          processNumber: item.processNumber,
          zeroVat: item.zeroVat,
        };
        internalEducation.push(education);
      }
      this.educationData = internalEducation;
    } else {
      this.pushEmptyEducation();
    }
  }

  private pushEmptyEducation() {
    const clone = cloneDeep(this.emptyEducation);
    this.educationData.push({ ...clone, fillingDate: moment().format("YYYY-MM-DD") });
  }

  public addEducation(): void {
    const clone = cloneDeep(this.emptyEducation);

    this.educationData.push({
      ...clone,
      fillingDate: moment().format("YYYY-MM-DD"),
      initialPriceList: this.currentPriceList,
    });
  }

  public get costBearerTypeIds(): Array<any> {
    return COST_BEARER_TYPES;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      studentData: {
        location: { required },
        branch: { required },
        title: { required },
        lastName: { required },
        firstName: { required },
        postalCode: { required },
        streetAndNumber: { required },
        birthDate: {
          required,
          // checkDateRegex: (value:any) => DATE_TIME_REGEX.date.test(value),
        },
        birthPlace: { required },
        mobilePhone: { required },
        email: { required, email },
        language: { required },
      },
      educationData: {
        $each: {
          costBearer: {
            required: requiredIf((val) => {
              return this.isCostBearerIdCompany(val.costBearerTypeId);
            }),
          },
          mainLicenseClass: { required },
          grantingType: { required },
          fillingDate: {
            required,
            // checkDateRegex: (value:any) => DATE_TIME_REGEX.date.test(value),
          },
          costBearerTypeId: { required },
          paymentWorkflow: { required },
          billingAddressFirstName: {
            required: requiredIf((val) => {
              return this.isCostBearerIdBillingAddress(val.costBearerTypeId);
            }),
          },
          billingAddressLastName: {
            required: requiredIf((val) => {
              return this.isCostBearerIdBillingAddress(val.costBearerTypeId);
            }),
          },
          billingAddressTitle: {
            required: requiredIf((val) => {
              return this.isCostBearerIdBillingAddress(val.costBearerTypeId);
            }),
          },
          billingAddressLocation: {
            required: requiredIf((val) => {
              return this.isCostBearerIdBillingAddress(val.costBearerTypeId);
            }),
          },
          billingAddressPostalCode: {
            required: requiredIf((val) => {
              return this.isCostBearerIdBillingAddress(val.costBearerTypeId);
            }),
          },
          billingAddressStreetAndNumber: {
            required: requiredIf((val) => {
              return this.isCostBearerIdBillingAddress(val.costBearerTypeId);
            }),
          },
          initialPriceList: { required },
        },
      },
    };
  }

  public onSubmit(): void {
    if (!this.$can(MODULE_CUSTOMERS_WRITE)) return;

    this.$v.$touch();

    if (this.$v.$invalid) {
      /* Active invalid tab student education */
      for (let i = 0; i < this.$v.educationData.$model.length; i++) {
        //@ts-ignore
        if (this.$v.educationData.$each[i].$invalid) {
          this.activeTab = 0;
          this.studentEducationActiveTabIndex = i;
          return;
        }
      }

      const checkList = [
        "location",
        "branch",
        "title",
        "lastName",
        "firstName",
        "postalCode",
        "streetAndNumber",
        "birthDate",
        "birthPlace",
        "mobilePhone",
        "email",
      ];

      for (let j = 0; j < checkList.length; j++) {
        //@ts-ignore
        if (this.$v.studentData[checkList[j]].$invalid) {
          this.activeTab = 0;
          return;
        }
      }

      /* Active tab drive buzz */
      //@ts-ignore
      if (this.$v.studentData.language.$invalid) {
        this.activeTab = 1;
      }

      return;
    }
    this.$emit("on-submit", {
      newChecklistTemplateId: this.studentData.checkListTemplateId,
      student: this.studentData,
      educations: this.educationData,
      bookedAddons: this.bookedAddons,
      file: this.file,
    });
  }

  public changeTab(index: number) {
    this.activeTab = index;
  }

  public onChangeStudentTab(index: number): void {
    this.studentEducationActiveTabIndex = index;
    if (this.selectedUnregisteredStudent) {
      const keyNumber = this.selectedUnregisteredStudent.subRegistrations[index].licenseClassWithKeyNumber?.keyNumber ?? "";
      const currentEducationLicenseClass = `${this.selectedUnregisteredStudent.subRegistrations[index].licenseClass.licenseClass}${keyNumber}`;
      this.onSelectEducation(currentEducationLicenseClass, index);
    }
  }

  public get getAddonsLabel(): TranslateResult {
    return `${this.$t("students.add_ons")} | ${this.$t("students.price_list")}`;
  }

  protected onUpload(file: File | null): void {
    this.file = file;
  }

  public onAbort(): void {
    this.closeCurrentTab();
  }

  @Watch("productAddonsSuccess", { immediate: true, deep: true })
  protected productAddonsSuccessWatcher(val: any): void {
    if (val) {
      this.bookedAddons = [];
    }
  }

  protected onSelectBranch(selectedOption: IBranch): void {
    if (selectedOption) {
      for (const education of this.educationData) {
        if (education.id === null) {
          education.initialPriceList = selectedOption?.priceList || null;
        }
      }
    }
  }

  protected get currentPriceList() {
    const branch = this.branches.find((b: IBranch) => b.id === this.studentData.branch?.id);
    return branch?.priceList || null;
  }

  @Provide("mandateRevoke")
  public async mandateRevokeProved(studentEducationId: number): Promise<void> {
    await this.findLivePayMandatesByStudentEducation(studentEducationId);

    if (this.livePayMandatesByStudentEducation && this.livePayMandatesByStudentEducation.length) {
      const lastLivePayMandate: ILivePayMandate | undefined = find(this.livePayMandatesByStudentEducation, function (mandate: ILivePayMandate) {
        return mandate.revocationDocumentId === null;
      });
      if (lastLivePayMandate && lastLivePayMandate.id) {
        await this.mandateRevoke(lastLivePayMandate.id);
        this.$root.$emit("refresh-student-education");
        this.$root.$emit("refresh-mandate-info");
      }
    }
  }

  @Watch("selectedUnregisteredStudent", { immediate: true, deep: true })
  protected selectedStudentChange(student: any): void {
    if (student && this.type == CREATE) {
      const internalEducations: any = [];
      let education: any = {};
      student.subRegistrations.forEach((registration: any) => {
        const keyNumber = registration.licenseClass?.keyNumber ?? "";
        education = {};
        education = {
          mainLicenseClass: registration.licenseClass.licenseClass ? `${registration.licenseClass.licenseClass}${keyNumber}` : null,
          licenseClassWithKeyNumber: {
            licenseClass: registration.licenseClass.licenseClass ? registration.licenseClass.licenseClass : null,
            keyNumber: registration.licenseClass.keyNumber ? registration.licenseClass.keyNumber : null,
          },
          theoryCourse: registration.theoryCourse
            ? {
                id: registration.theoryCourse.id,
                name: registration.theoryCourse.name,
              }
            : null,
          secondTheoryCourse: registration.secondTheoryCourse
            ? {
                id: registration.secondTheoryCourse.id,
                name: registration.secondTheoryCourse.name,
              }
            : null,
          thirdTheoryCourse: registration.thirdTheoryCourse
            ? {
                id: registration.thirdTheoryCourse.id,
                name: registration.thirdTheoryCourse.name,
              }
            : null,
          paymentWorkflow: registration.paymentWorkflow ? registration.paymentWorkflow : null,
        };

        let unregisteredEducation: IStudentEducationAndBillingAddress = {
          id: null,
          bf17: false,
          additionalClass: false,
          fillingDate: moment().format("YYYY-MM-DD"),
          mainLicenseClass: education.mainLicenseClass,
          licenseClassWithKeyNumber: education.licenseClassWithKeyNumber,
          grantingType: null,
          firstExistingLicenseClass: student.existingDrivingLicense || null,
          secondExistingLicenseClass: null,
          firstExistingDrivingLicenseNumber: null,
          secondExistingDrivingLicenseNumber: null,
          firstExistingLicenseClassIssueDate: null,
          secondExistingLicenseClassIssueDate: null,
          costBearer: null,
          costBearerEmail: null,
          costBearerTypeId: COST_BEARER_TYPES[0],
          theoryCourse: education.theoryCourse,
          secondTheoryCourse: education.secondTheoryCourse,
          thirdTheoryCourse: education.thirdTheoryCourse,
          instructors: [],
          billingAddressId: null,
          billingAddressStudentEducationId: null,
          billingAddressTitle: null,
          billingAddressFirstName: null,
          billingAddressLastName: null,
          billingAddressPostalCode: null,
          billingAddressLocation: null,
          billingAddressStreetAndNumber: null,
          initialPriceList: null,
          paymentWorkflow: education.paymentWorkflow,
          activeLivePayMandate: null,
          combined: null,
          automaticPaymentEnabled: false,
          practicalEducationFinishDate: null,
          theoreticalEducationFinishDate: null,
          processNumber: null,
          zeroVat: null,
        };

        internalEducations.push(unregisteredEducation);

        // const keyNumber = registration.licenseClassWithKeyNumber?.keyNumber ?? "";
        this.onSelectEducation(`${registration.licenseClass.licenseClass}${keyNumber}`, 0);
        // this.onSelectEducation(`${registration.mainLicenseClass}${keyNumber}`, 0);
      });
      // const education = {
      //   mainLicenseClass: student.licenseClass.licenseClass ? student.licenseClass.licenseClass : null,
      //   licenseClassWithKeyNumber: {
      //     licenseClass: student.licenseClass.licenseClass ? student.licenseClass.licenseClass : null,
      //     keyNumber: student.licenseClass.keyNumber ? student.licenseClass.keyNumber : null,
      //   },
      //   theoryCourse: student.theoryCourse
      //     ? {
      //         id: student.theoryCourse.id,
      //         name: student.theoryCourse.name,
      //       }
      //     : null,
      //   secondTheoryCourse: student.secondTheoryCourse
      //     ? {
      //         id: student.secondTheoryCourse.id,
      //         name: student.secondTheoryCourse.name,
      //       }
      //     : null,
      //   thirdTheoryCourse: student.thirdTheoryCourse
      //     ? {
      //         id: student.thirdTheoryCourse.id,
      //         name: student.thirdTheoryCourse.name,
      //       }
      //     : null,
      //   paymentWorkflow: student.paymentWorkflow ? student.paymentWorkflow : null,
      // };
      //
      // const unregisteredEducation: IStudentEducationAndBillingAddress = {
      //   id: null,
      //   bf17: false,
      //   additionalClass: false,
      //   fillingDate: moment().format("YYYY-MM-DD"),
      //   mainLicenseClass: education.mainLicenseClass,
      //   licenseClassWithKeyNumber: education.licenseClassWithKeyNumber,
      //   grantingType: null,
      //   firstExistingLicenseClass: student.existingDrivingLicense || null,
      //   secondExistingLicenseClass: null,
      //   firstExistingDrivingLicenseNumber: null,
      //   secondExistingDrivingLicenseNumber: null,
      //   firstExistingLicenseClassIssueDate: null,
      //   secondExistingLicenseClassIssueDate: null,
      //   costBearer: null,
      //   costBearerEmail: null,
      //   costBearerTypeId: COST_BEARER_TYPES[0],
      //   theoryCourse: education.theoryCourse,
      //   secondTheoryCourse: education.secondTheoryCourse,
      //   thirdTheoryCourse: education.thirdTheoryCourse,
      //   instructors: [],
      //   billingAddressId: null,
      //   billingAddressStudentEducationId: null,
      //   billingAddressTitle: null,
      //   billingAddressFirstName: null,
      //   billingAddressLastName: null,
      //   billingAddressPostalCode: null,
      //   billingAddressLocation: null,
      //   billingAddressStreetAndNumber: null,
      //   initialPriceList: null,
      //   paymentWorkflow: education.paymentWorkflow,
      //   activeLivePayMandate: null,
      //   combined: null,
      //   automaticPaymentEnabled: false,
      //   practicalEducationFinishDate: null,
      //   theoreticalEducationFinishDate: null,
      //   processNumber: null,
      // };
      //
      // internalEducations.push(unregisteredEducation);
      //
      // const keyNumber = education.licenseClassWithKeyNumber.keyNumber ?? "";
      //
      // this.onSelectEducation(`${education.mainLicenseClass}${keyNumber}`, 0);

      this.educationData = internalEducations;

      this.studentData.title = student.salutation;
      this.studentData.lastName = student.lastName;
      this.studentData.firstName = student.firstName;
      this.studentData.birthDate = student.birthDate;
      this.studentData.birthPlace = student.birthPlace;
      this.studentData.nationality = student.nationality;
      this.studentData.language = student.language;

      this.studentData.location = student.location;
      this.studentData.streetAndNumber = student.address;
      this.studentData.email = student.email;
      this.studentData.mobilePhone = student.mobilePhone;
      this.studentData.postalCode = student.postalCode;
      this.studentData.branch = student.branch
        ? {
            id: student.branch.id,
            name: `${student.branch.postalCode} - ${student.branch.location}`,
          }
        : "";
      this.studentData.info =
        this.$t("question.intend") +
        "\n" +
        (student?.freeTextFutureIntentions ? student?.freeTextFutureIntentions : "") +
        "\n" +
        this.$t("question.important") +
        "\n" +
        (student?.freeTextImportantThings ? student?.freeTextImportantThings : "");
    }
  }

  public async onSelectEducation(licenseClass: string, index: number, fetchKeyNumbers = true) {
    if (fetchKeyNumbers) {
      await this.findKeysByLicenseClass(licenseClass);
    }
    await this.fetchGrantingTypesByLicenseClass(licenseClass);

    if (fetchKeyNumbers) {
      this.$set(this.keyNumbers, index, this.keysByLicenseClass);
    }
    this.$set(this.grantingTypes, index, this.grantingTypesOptions);
  }

  public beforeDestroy(): void {
    this.setSelectedUnregisteredStudent(null);
  }

  @Watch("registrationInfo", { immediate: true, deep: true })
  public onRegistrationInfoChange(registration: any) {
    if (registration) {
      this.$bvModal.show("registrationInfoModal");
    }
  }

  public onPopoverInfoClose(): void {
    this.popoverInfoShow = false;
  }
}
